<template>
  <div class="detail-all">
    <div class="courseGoodsDetailCont">
      <el-row>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(item, index) in breadcrumb" :key="index">
              <span :class="index === 1?'breadName active-color':'breadName'" @click="goView(index)">{{item.nameV}}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
      </el-row>
      <div class="detail-comm">
        <div class="goods-poster">
              <img class="course-bg-img" :src="detailObj.picUrl"/>
              <!-- <p class="fix-img" v-show="detailObj.taskType">{{tagName(detailObj.taskType)}}</p> -->
            </div>
            <div class="base-info-comm">
              <div class="name-tag-w">
                <div class="title-video"> 
                  <p class="course-name hide">{{detailObj.name}}</p>
                  <ul class="video-course">
                    <li class="item" v-show="detailObj.videoNum"><span class="num">{{detailObj.videoNum}}</span><span class="text">视频</span></li> 
                    <li class="bar" v-show="detailObj.videoNum && detailObj.studyHoursNum"></li>
                    <li class="item" v-show="detailObj.studyHoursNum"><span class="num">{{detailObj.studyHoursNum}}</span><span class="text">课时</span></li> 
                  </ul>

                </div>
                <!-- 左侧标签 周期 职业  -->
                <div class="content-left-wraper"> 
                  <div class="content-left">
                    <ul class="tag-list">
                      <li class="tag-item hide"
                        v-for="(tag, index) in detailObj.labelNameList"
                        :key="index"
                      >
                        <!-- <el-tooltip :content="tag" placement="top"> -->
                          <div class="text">{{tag}}</div>
                        <!-- </el-tooltip> -->
                      </li>
                    </ul>
                    <div class="zhouqi"> 
                      <p class="sign-time" v-show="detailObj.signUpStartTime && detailObj.signUpEndTime">
                        <span class="label">报名周期：</span>
                        <span class="time">{{formatDate(detailObj.signUpStartTime)}} - {{formatDate(detailObj.signUpEndTime)}}</span>
                      </p>
                      <p class="blank-bar" v-show="detailObj.signUpStartTime && detailObj.signUpEndTime">1</p>
                      <p class="sign-time peixun" v-if="detailObj.trainingStartTime && detailObj.trainingEndTime">
                        <span class="label">培训周期：</span>
                        <span class="time">{{formatDate(detailObj.trainingStartTime)}} - {{formatDate(detailObj.trainingEndTime)}}</span>
                      </p>
                      <p class="sign-time peixun" v-else-if="detailObj.cycleType === 3">
                        <span class="label">培训周期：</span>
                        <span class="time">长期有效</span>
                      </p>
                      <p class="sign-time peixun" v-else-if="detailObj.cycleType === 2">
                        <span class="label">培训周期：</span>
                        <span class="time">随到随学</span>
                      </p>
                    </div>
                    <div class="zhiye"> 
                      <p class="sign-time" v-if="detailObj.profession">
                        <span class="label">所属职业：</span>
                        <span class="time">{{detailObj.profession}}</span>
                      </p>
                      <p class="blank-bar" v-show="detailObj.profession && detailObj.workType">1</p>

                      <p class="sign-time" v-if="detailObj.workType">
                        <span class="label">所属工种：</span>
                        <span class="time">{{detailObj.workType}} </span>
                      </p>
                      <p class="blank-bar" v-show="detailObj.skillLevel">1</p>

                      <p class="sign-time" v-if="detailObj.workType && detailObj.skillLevel">
                        <span class="label">技能等级：</span>
                        <span class="time">{{detailObj.skillLevel}}</span>
                      </p>
                    </div>
                  </div> 
                  <img class="content-rignt-img" :src="detailObj.resourceProviderLogo"/>
                </div>
                
              </div>
              <div class="price-sign-num">
                <div class="price-w" v-if="fromType !== 3 && ($store.state.app.isWdBranch===true)">
                  <div v-if="isLogin">
                    <div v-if="!detailObj.hidePriceTag">
                      <!-- <div class="goodsPrice" v-if="detailObj.isFree"><span class="freePrice">免费</span></div>
                      <div class="goodsPrice" v-else>
                        <span class="label">商品售价：</span>
                        <span class="normalPrice">¥{{detailObj.salePrice}}</span>
                        <span class="discountPrice" v-if="detailObj.basePrice">¥{{detailObj.basePrice}}</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="price-w" v-if="fromType === 3 && !wdSubBranch">
                  <p class="price">
                    <!-- <span class="label">商品售价：</span>
                    <span class="text-free" v-if="detailObj.isFree">免费</span>
                    <span class="text" v-else>￥{{detailObj.salePrice}}</span>

                    <span class="or-price" v-if="!detailObj.isFree&&detailObj.basePrice">￥{{detailObj.basePrice}}</span> -->
                  </p>
                  <p class="price">
                    <!-- <span class="label">预计收益：</span><span class="text color2">￥{{detailObj.preIncome}}</span> -->
                  </p>
                </div>
                <div class="now-sign-up" v-if="fromType !== 3">
                    <span class="signUpNum" v-if="isJoinPer=='true'">{{detailObj.purchaseNo}}人已报名</span>
                    <!-- 如果是教培中心未登录条件下：来源是伟东显示立即报名， -->

                    <el-button :type="isInSignUpCycle?'warning':'info'" v-if="detailObj.status==1&&!detailObj.isOrder && !isRenShe && token" @click="toPay(true)">立即报名</el-button>
                    <el-button :type="isInSignUpCycle?'warning':'info'" v-else-if="detailObj.status==1&&!detailObj.isOrder && isRenShe && detailObj.resourceProviderFlag || (!isRenShe && !token)" @click="toPay">立即报名</el-button>
                    <div class="quickLearn" v-else-if="detailObj.isOrder">
                      <!-- <el-button @click="toStudy" v-if="isOver === '立即学习' || isOver === '继续学习' && quickLearnInfo" style="width:88px;height:32px;padding:0">任务大纲</el-button> -->
                      <el-button :type="isOverType=='oks'?'warning':'info'" style="margin-left:24px" :style="theme === 'minzheng' && isOver === '立即学习' || isOver === '继续学习' ? 'background:#CA4C2D' : isOver === '立即学习' || isOver === '继续学习' ? 'background:#2D67FF' : ''"  @click="quickLearn(1)">{{isOver}}</el-button>
                    </div>
                    <el-button type="info" v-else-if="isRenShe && detailObj.resourceProviderFlag || (!isRenShe)" disabled >{{statusText}}</el-button>
                </div>
                <div class="" v-else>
                  <div class="distributed" v-if="!wdSubBranch">
                    <span class="dis-text"  v-if="!detailObj.isDistribute">点击推广后，商品将自动加入自己的店铺～</span>
                    <el-button type="warning" @click="toDistribute">{{!detailObj.isDistribute?'推广':'已推广'}}</el-button>
                  </div>
                </div>
              </div>
            </div>
      </div>
      <div class="detail-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="简介" name="first">
            <div class="detail" v-html="detailObj.desc">
              <!-- {{detailObj.desc}} -->
            </div>
          </el-tab-pane>
          <el-tab-pane label="目录" name="second" v-if="isTab=='true'&&detailObj.goodsType!=3">
            <div class="list" v-if="Object.keys(catalogList).length>0">

              <TaskItem  :taskCatalogList="catalogList" :goodsId="goodsId" :isOverType="isOverType" :detailObj="detailObj" :fromType="fromType"></TaskItem>
            </div>
            <div v-else class="noData">
              <img src="../../../assets/images/common/nodata.png" width="242"/>
              <div>课程目录正在建设中，敬请期待～</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <facedialog v-if="verification" :address="verificationAddress" :failAddress="verificationFailAddress" :type=1 :pauseFrame="true"></facedialog>
    <!-- 分销推广弹框 -->
    <!-- 首次推广 -->
    <el-dialog
      :visible="isFirstTuiguang"
      custom-class="first-tuiguang"
      :close-on-click-modal="false"
      width="400px"
      height="224px" 
    >
      <div class="titile-tip">提示</div>
      <p class="tip-text">同一个培训项目同一个培训工种，不同技能等级仅可推广同一个资源供应商内容</p>
      <div class="btn-list">
        <div class="btn-text" @click="isFirstTuiguang = false">取消</div>
        <div class="btn-text" @click="confirmTuiguang">确定推广</div>
      </div>
    </el-dialog>
    <el-dialog
      title="推广"
      :visible="dialogVisible"
      custom-class="qrcoddialog"
      :close-on-click-modal="false"
      @close="closeIt()"
      :before-close="handleClose"
      
      >
      <div class="share-box">
        <div>
          <h3>方法一：通过链接</h3>
          <p>分享此链接到微信群和微信好友</p>
          <div class="share-url">{{detailObj.distributionUrl}}</div>
          <div class="share-btn">
            <div class="copyUrl" @click="copyUrl()" data-clipboard-text="12233333">复制链接</div>
          </div>
        </div>
        <div>
          <h3>方法二：通过二维码</h3>
          <div class="code-box">
            <div id="qrcode" ref="qrcode"></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 教培中心未登录来源是伟东报名 -->
   <el-dialog
      :visible="formWdDialog"
      custom-class="linkDialog"
      :close-on-click-modal="false"
      :before-close="handleCloseFormWd"
      width="480px"
      title="提示"
    >
      <p class="tip-text">请注意：</p>
      <p class="tip-text">1、报名前，请联系机构了解线下实训场所</p>
      <p class="tip-text">2、客服电话：400-8555-686转1</p>
      <div class="btn-list" slot="footer" align="center">
        <!-- <el-button class="btn-text" @click="formWdDialog = false">取消</el-button>
        <el-button  type="primary" class="btn-text" @click="toPay">去报名</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Clipboard from 'clipboard'
import QRCode from "qrcodejs2";
import TaskItem from '../components/taskItem';
import { setTimeout } from 'timers';
import isRenShe from '../../../utils/judgeDomain.js'
export default {
  name: 'courseGoodsDetail',
  components: {
    TaskItem
  },
  // props: {
  //   fromType: {
  //     type: [Number , String],
  //     default: 3 // 1. 人社首页  学员登陆 分销商主页 3，分销商登陆 
  //   }
  // },
  data () {
    return {
          token:localStorage.getItem('token'),
          isRenshe: false,
          formWdDialog:false,
          wdSubBranch:false,
          /** 
           * 人脸识别
           */
          verification: false,
          verificationAddress: {}, // 人脸识别成功返回当前页面需要的地址参数
          verificationFailAddress: {},// 人脸识别失败返回当前页面需要的地址参数
          isFaceRecognize:null, // 是否需要人脸识别
          goodsKind:1,//1 普通商品 2 分销商品
          theme: '',
          goodsId:parseInt(this.$route.query.goodsId),
          detailObj:{
              basePrice: 0,//划线价格
              days: 0,//购买多少天有效
              desc: "",//简介
              endTime: "",
              goodsType: 1,//商品引用类型1.训练营 2.培训任务
              id: 0,
              isFree: 0,//是否免费
              isOrder: 0,//是否已经购买 0 否 1 是
              name: "",//商品名称
              picUrl: "",
              purchaseNo: 0,//报名人数
              referId: 0,//商品引用ID
              salePrice: 0,//销售价格
              startTime: "",
              status: 1,//状态 0下架 1上架 2 待上架
              taskType: 1,//任务类型 1.必修课 2.选修课 3.公开课
              tryWatch: 1,//是否支持试看
              tryWatchList: [//可试看列表
                {
                  itemId: 0,
                  value: 0
                }
              ],
              validityType: 1//商品有效期类型（1:永久有效、2:期间、3:固定时间、4:购买后期间）
          },
          activeName:'first',
          catalogList:[],
          isFirst:true,//是否一级
          isTab:'true',
          isJoinPer:'true',
          quickLearnInfo:null,
          detailDataCompleted:false,
          dialogVisible: false,
          isLogin: false,
          fromType: 1, //人社首页  学员登陆 分销商主页 3，分销商登陆
          breadcrumb: [],
          isFirstTuiguang: false, // 是否是第一次推广
          isHaveOffTrain:false
    }
  },
  filters: {

  },
  created () {
    this.isRenShe = isRenShe;
    if(!localStorage.getItem('token')){
        this.fromType = 1;
    }else{
      this.currBranchInfo();
      if(localStorage.getItem('branchId')){
          this.fromType = 1;
      }else{
          this.fromType = 3;
      }
    }
    this.theme = localStorage.getItem('theme');
    this.goodsKind = this.$route.query.goodsKind * 1//1 普通商品 2 分销商品
    this.activeName = this.theme === 'minzheng' ? 'second':'first'
    this.layoutType = this.$route.query.layoutType
    var configurationArr = JSON.parse(localStorage.getItem('configurationArr'));
      var isTab = configurationArr.filter((item)=>{
        return item.key == 'is_show_catalog_tab';
      })
      this.isTab = isTab[0].value;
      var isJoinPer = configurationArr.filter((item)=>{
        return item.key == 'is_show_commodity_number_buyer_detail_page';
      })
      this.isJoinPer = isJoinPer[0].value;
      // console.log(this.isTab)
      // console.log(this.isJoinPer)
      if(localStorage.getItem('token')) {
        this.isLogin = true
      } else {
        this.isLogin = false

      }
  },
  computed: {
    // 报名周期是否过期
    isInSignUpCycle(){
      if(this.detailObj.signUpEndTime){
        if((new Date() - new Date(this.detailObj.signUpEndTime)) > 0 || (new Date() - new Date(this.detailObj.signUpStartTime)) < 0) {
          return false
        } else {
          return true
        }
      }else{
        return true
      }
    },

      /**
       * 
       */
    //课程类型
    type(){
      if(this.detailObj.taskType==3){
        return '公开课'
      }else if(this.detailObj.taskType==2){
        return '选修课'
      }else{
        return '必修课'
      }
    },
    //是否过期文案显示
    isOver(){
      if(this.detailObj.endTime&&this.detailObj.validityType==4){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>nowTime){
          return !this.quickLearnInfo ? '立即学习' : this.quickLearnInfo.firstStudy ? '立即学习':'继续学习';
        }else{
          return '已过期'
        }
      }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>=nowTime&&startTime<=nowTime){
          return !this.quickLearnInfo ? '立即学习' : this.quickLearnInfo.firstStudy ? '立即学习':'继续学习';
        }else{
          if(startTime>=nowTime){
            return '未开始'
          }else{
            return '已过期'
          }
        }
      }else{
        return !this.quickLearnInfo ? '立即学习' : this.quickLearnInfo.firstStudy ? '立即学习':'继续学习';
      }
    },
    //是否过期
    isOverType(){
      if(this.detailObj.isOrder){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();  
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            return 'oks'
          }else{
            return 'no'
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
            return 'oks'
          }else{
            if(startTime>=nowTime){
              return 'no1'
            }else{
              return 'no2'
            }
          }
        }else if(this.detailObj.validityType==1){
          return 'oks'
        }else{
          return 'ok'
        }
      }else{
        return 'ok'
      }
    },
    //上架状态
    statusText(){
        if(this.detailObj.status==1){
          return '上架'
        }else if(this.detailObj.status==0){
          return '下架'
        }else{
          return '待上架'
        }
    },
    //有效期
    time(){
      if(this.detailObj.validityType==1){
        return "永久有效"
      }else if(this.detailObj.validityType==2){
        let sarr = this.detailObj.startTime.split(' ');
        let stimeArr = sarr[0].split('-');
        stimeArr[0] = stimeArr[0]+'年';
        stimeArr[1] = stimeArr[1]+'月';
        stimeArr[2] = stimeArr[2]+'日';
        let snewTime = stimeArr.join('');
        let earr = this.detailObj.endTime.split(' ');
        let etimeArr = earr[0].split('-');
        etimeArr[0] = etimeArr[0]+'年';
        etimeArr[1] = etimeArr[1]+'月';
        etimeArr[2] = etimeArr[2]+'日';
        let enewTime = etimeArr.join('');
        return snewTime+"-"+enewTime
      }else if(this.detailObj.validityType==3){
        if(this.detailObj.startTime&&this.detailObj.endTime){
          let sarr = this.detailObj.startTime.split(' ');
          let stimeArr = sarr[0].split('-');
          stimeArr[0] = stimeArr[0]+'年';
          stimeArr[1] = stimeArr[1]+'月';
          stimeArr[2] = stimeArr[2]+'日';
          let snewTime = stimeArr.join('');
          let earr = this.detailObj.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return snewTime+"-"+enewTime
        }else{
          let earr = this.detailObj.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return "至"+enewTime
        }
      }else{
        return "报名后"+this.detailObj.days+"天内有效"
      }
    }
  },
   mounted() {

     this.getGoodsDetail(2);//1 普通商品 2 分销商品
    // this.getList();
    this.getBreadList()

  },

 
  methods: {
      // 是否为伟东下的渠道方
      currBranchInfo(){
          this.$api.rensheUsercenter.currBranchInfo().then(res=>{
              if(res.data){
                  this.wdSubBranch = res.data.wdSubBranch;
              }
          })
      },
      goView(index) {
        if(index === 0 ) {
          this.$router.push(this.breadcrumb[0])
        }
      },
      getBreadList() {
        let arr = JSON.parse(localStorage.getItem('rehsheBread')) || []
        arr.push({nameV: '项目详情'})
        console.log(arr, 'aa')
        this.breadcrumb = arr
      },
      
      //
      formatDate(date) {
        return dayjs(date).format('YYYY.MM.DD') 
      },
      //返回
      back(){
        this.$router.go(-1);
      },
      isToStudy(){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            this.toStudy()
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
              this.toStudy()
          }
        }else{
          this.toStudy()
        }
        
      },
      /**
       * 查询培训任务规则 
       */
      async findfaceNeed(taskId) {
          await this.$api.examination.findfaceNeed(taskId).then(res=>{
              this.isFaceRecognize = res.data.isCourseFaceRecognize;
          })
      },
    /**
     * quickLearn 快速学习 跳转课程播放页
     */
     async quickLearn(type){
            if(this.detailObj.goodsType === 2 && type === 1){ // 培训任务 
                await this.findfaceNeed(this.detailObj.referId)
            }
            if(!this.quickLearnInfo && type === 1){
                this.isToStudy();
                return false;
            }
            const params = {
                taskId:this.detailObj.referId,
                taskType:this.detailObj.goodsType
            }
           await this.$api.course.quickLearn({params}).then(res => {
                this.quickLearnInfo = res.data;
                if(res.data && type === 1){
                    /**
                     * 判断是否人脸识别  如果进度等于100 不需要人脸识别
                     */
                    console.log(this.isFaceRecognize,res.data.learnRate);
                    if(this.isFaceRecognize && res.data.learnRate !== 100){
                        this.verification = true;
                        console.log(this.verification);
                        this.verificationAddress = {
                            path:'/course/play',
                            query:{
                                trainId:res.data.taskId,
                                courseId:res.data.courseId,
                                detailId:res.data.detailId,
                                studyProcess:res.data.learnRate,
                                courseName:res.data.courseName,
                                taskType:res.data.taskType,//2：任务；1：训练营
                                backPathName:res.data.taskType == 1 ? '/course/catalog' : '/learning/courseDetails'
                            }
                        }
                        this.verificationFailAddress = {
                            path:'/courseGoodsDetail',
                            query:{
                                goodsId:this.$route.query.goodsId,
                                goodsKind:this.$route.query.goodsKind
                            }
                        };
                    }else {
                        this.$router.push({
                            path:'/course/play',
                            query:{
                                trainId:res.data.taskId,
                                courseId:res.data.courseId,
                                detailId:res.data.detailId,
                                studyProcess:res.data.learnRate,
                                courseName:res.data.courseName,
                                taskType:res.data.taskType,//2：任务；1：训练营
                                backPathName:res.data.taskType == 1 ? '/course/catalog' : '/learning/taskDetail'
                            }
                        })
                    }
                    
                }
                
            })
        
    },
    // 跳转任务大纲 训练营大纲
    toStudy(){
      if(this.detailObj.goodsType==1){
        this.$router.push({
          path:'/course/catalog',
          query:{
            taskId:this.detailObj.referId,//训练营id
            taskType:this.detailObj.goodsType,
            goodsId:this.$route.query.goodsId,
            backPath:this.$route.path,
            goodsKind:this.$route.query.goodsKind
          }
        })
      }else if(this.detailObj.goodsType==2){
        // this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
        this.$router.push({
          path:'/learning/taskDetail',
          query:{
            id:this.detailObj.referId,//培训任务id
            goodsId:this.$route.query.goodsId,
            backPath:this.$route.path,
            type:this.detailObj.goodsType,
            goodsKind:this.$route.query.goodsKind
          }
        })
      }else if(this.detailObj.goodsType==3){
        this.$router.push({
          path:'/assess',
          query:{
            assessId:this.detailObj.referId,//测评id
            refId:this.$route.query.goodsId,
            refType:2,
            goodsId:this.$route.query.goodsId,
            pathName:this.$route.path,
            goodsKind:this.$route.query.goodsKind
          }
        })
      }
    },
    //获取商品基本信息
    async getGoodsDetail(goodsKind){
     //1 普通商品 2 分销商品
      if(goodsKind == 2){
        this.getDistributionGoodsDetail()
      }else {
        this.getCommonGoodsDetail()
      }
    },
    // 获取普通商品详情
    async getCommonGoodsDetail(){
      await this.$api.courseGoodsDetail.findGoodsDetailById({ goodsId: this.goodsId }).then(res=>{
        if(res.code===0 && res.data ){
          this.detailDataCompleted = true;
          this.detailObj = res.data; 
          console.log(this.detailObj.labelNameList)
          this.quickLearn();
          this.getList();
        }
      })
    },
    // 获取分销商品详情
    async getDistributionGoodsDetail(){
      await this.$api.openPlatform.findGoodsDetailById({ goodsId: this.goodsId }).then(res=>{
        if(res.code===0 && res.data){
          this.detailDataCompleted = true;
          this.detailObj = res.data;
          // 处理字段不一致问题
            //分销商品 0审核中；1已上架；2已下架；3审核不通过
            //普通商品 0下架 1上架 2 待上架
          this.detailObj.status = res.data.status == 2? 0:res.data.status  
          this.quickLearn()

          this.getList();
        }
      })
    },
    //目录数据查询
    getList() {
      // console.log(this.detailObj.goodsType)
      if(this.detailObj.goodsType==1){
        //训练营目录
        this.$api.courseGoodsDetail.findTrainListById(
          {
            id: this.detailObj.referId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              let tempArr = [];
                let endData = [];
                let timeArr = [];
                let num = [];
                res.data.menuList.forEach(tiem => {
                    tiem.childItemList = [];
                    num.push(tiem.lastStudyTime);
                    if (tiem.lastStudyTime !== null) {
                        timeArr.push(tiem.lastStudyTime);
                    }
                });
                this.timelength = timeArr.sort(function(a, b) {
                    return b > a;
                });
                res.data.menuList.forEach(item => {
                    tempArr.push(item.stageName);
                });
                let setName = [...new Set(tempArr)]; //.sort();
                setName.forEach((el, index) => {
                    let datalist = [];
                    res.data.menuList.forEach(items => {
                        if (el === items.stageName) {
                            items.stageName = items.label
                            datalist.push(items);
                        }
                    });
                    endData.push({
                        stageName: el,
                        id: index,
                        type:0,
                        childItemList: datalist,
                        sort:1
                    });
                });
                this.catalogList = endData;
                // console.log(this.catalogList)
            }else{
              console.log(res.data.message)
            }
        })
      }
      else if(this.detailObj.goodsType==2){
        //培训任务目录
        this.$api.courseGoodsDetail.findTaskListById1(
          {
            id: this.detailObj.referId,
            version:1//兼容接口版本
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code==0){
              this.catalogList = res.data.taskItemVoList;
              //判断是否有线下实训
              this.catalogList.forEach((citem)=>{
                if(citem.type==8){
                  this.isHaveOffTrain = true;
                }
              })
            }
        })
      }

    },
    handleClick() {
      // this.dialogVisible=true;
      if(document.getElementsByTagName('video')[0]){
        document.getElementsByTagName('video')[0].pause()
      }
    },
    handleCloseFormWd(){
      this.formWdDialog = false;
    },
    //立即报名
    toPay(bool){
      if(this.detailObj.signUpEndTime && this.detailObj.signUpStartTime) {
        if(new Date() - new Date(this.detailObj.signUpEndTime.replace(/-/g, "/")) > 0) {
          this.$message({
            message: '报名已过期',
            type: 'warning'

          })
          return
        } else if((new Date() - new Date(this.detailObj.signUpStartTime.replace(/-/g, "/"))) < 0){
          this.$message({
            message: '报名未开始',
            type: 'warning'
          })
          return
        }
      }
      // 有报名提示的
      if(bool===true){
        this.formWdDialog = true;
        return
      }
      // 区分是人社域名还是分销商主页域名

      if(localStorage.getItem('token')){
        var obj = {
          goodsId:this.goodsId,
          orderSource:1,
          orderType:this.goodsKind == 2? 4:null //4渠道分销(订单类型)
        }
          //下单
        // if(this.detailObj.isFree){

        //   this.$api.courseGoodsDetail.order(obj).then(res=>{
        //     if(res.code==0){
        //       this.$message({
        //         message: '报名成功',
        //         type: 'success'
        //       })
        //       this.detailObj.isOrder = 1;
        //     }
        //   })
        // }else{ // 有价格
          this.$api.courseGoodsDetail.order(obj).then(res=>{
            if(res.code==0){
              this.formWdDialog = false;
              if(res.data.placeOrderSuccess && res.data.realPayPrice  > 0) {
                this.$router.push(`/orderPage?orderNo=${res.data.orderNo}&dymode=1`)

              } else if(res.data.placeOrderSuccess && res.data.realPayPrice  === 0){
                //
                this.$message({
                  message: '报名成功',
                  type: 'success'
                })
                this.detailObj.isOrder = 1;
              } else {
                if(res.data.url) {
                  window.open(res.data.url, '_self')
                } else {
                  this.$router.go(-1)
                }
              }
            }
          })
        // }



      }else{
        if(localStorage.getItem('branchId')){
          // 如果当前时人社域名 widow.open 打开weidongToc的login 否则 直接到login页面
          this.$router.push({
            path: '/login',
            query: {
              name: '/mall/goodsDetail',
              goodsId: this.goodsId,
              goodsKind: this.goodsKind || this.$route.query.goodsKind, //1 普通商品 2 分销商品
              fromType:this.fromType
            }
          })
        }else{
          const hostname = window.location.hostname
          if((/ethrss.wdeduc.com*/).test(window.location.hostname)){
					window.open(`https://43515236.channel.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`, '_self')
				}	else if((/jiafu.ethrss.cn*/).test(window.location.hostname)) {
					window.open(`https://18188548.jiafu.ethrss.cn/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`, '_self')
				}	
					if(hostname.includes('ethrss') && !hostname.includes('jiafu')){
            //
            if(window.location.href.indexOf('testing3') > -1){
              window.open(`http://12639648.channel.testing3.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`, '_self')

            }else if(window.location.href.indexOf('fat') > -1){
              window.open(`https://12655955.channel.fat.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`, '_self')
            }else if(window.location.href.indexOf('uat') > -1){
              window.open(`https://57796486.channel.uat.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`, '_self')

            } else{
              window.open(`https://43515236.channel.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`, '_self')
              
            }
          } else if(hostname.includes('mohrss')) {
            if (window.location.href.indexOf('testing3') > -1) {
							// window.open(
							// 	`http://12639648.channel.testing3.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
							// 	'_self'
							// )
						} else if (window.location.href.indexOf('fat') > -1) {
							// window.open(
							// 	`https://12655955.channel.fat.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
							// 	'_self'
							// )
						} else if (window.location.href.indexOf('uat') > -1) {
							window.open(
								`https://32156444.channel.uat.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
								'_self'
							)
						} else {
							// window.open(
							// 	`https://24868825.channel.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
							// 	'_self'
							// )
						}
          }
        }
      }
    },
      //关闭推广弹框
    closeIt(){
      // this.$refs.qrcode = '';
      setTimeout(() => {
        document.getElementById("qrcode").innerHTML = "";
      })
    },
    //推广
    openDiog(){
      this.dialogVisible = true
      this.qrcodeimg();
    },
    //复制链接
    copyUrl(){
      var url = this.detailObj.distributionUrl
      var clipboard = new Clipboard('.copyUrl', {
          text: function () {
              return url
          }
      })
      // 复制成功
      clipboard.on('success', () => {
        this.$message.success('链接已复制')
        // 释放内存
        clipboard.destroy()
      })
      // 复制失败
      clipboard.on('error', () => {
          // 不支持复制
          this.$message.danger('复制失败')
          // 释放内存
          clipboard.destroy()
      })
    },
    //生成二维码
    qrcodeimg () {
       var url = this.detailObj.distributionUrl
        setTimeout(() => {
                new QRCode(this.$refs.qrcode, {
                    text:url,
                    width: 110,
                    height: 110,
                    // colorDark: '#ffffff', // 二维码颜色
                    colorLight: '#ffffff', // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
                }, 100)
            })
    },
    handleClose() {
      this.dialogVisible = false
    },
    //申请分销
    toDistribute(){
      if(localStorage.getItem('token')){
        if(this.detailObj.isDistribute) {
          //
          this.openDiog()
          // this.$message({
          //   message: '已推广成功',
          //   type: 'warning'
          // })
          return 
        } else { // 未推广
          //  this.isFirstTuiguang = true
          this.confirmTuiguang()
        }
        
      }else{
        localStorage.setItem('loginBy',1)
        this.$router.push({
            path: '/login',
            query: {
                name: '/mall/goodsDetail',
                goodsId:this.$route.query.goodsId,
                goodsKind:this.$route.query.goodsKind
            }
        })
      }
    },
    // 确定推广
    async confirmTuiguang() {
        let res = await this.$api.mall.canPromote({id:this.goodsId})
        if(res.code === 0) {
          let res = await this.$api.openPlatform.getDistribute(
            {
              distributionGoodsId: this.detailObj.id,
            })
          if(res.code == 0) {
            if(this.isHaveOffTrain){
              this.$message.success("推广成功，登录渠道方管理后台商品管理添加实训计划");
            }else{
              this.$message.success("推广成功");
            }
              await this.getGoodsDetail(this.goodsKind);//1 普通商品 2 分销商品
              setTimeout(()=>{
                this.dialogVisible = true
                setTimeout(() => {
                  this.qrcodeimg()
                },300)
              },1000)
              
          }
        }
       
    }
  },

};
</script>

<style lang="stylus" scoped>
 @import "../css/courseGoodsDetail.styl"
  /deep/.linkDialog{
    .el-dialog__header{
      width: 100%;
      height: 50px;
      background: rgb(247, 250, 255);
      padding: 12px 20px;
      box-sizing: border-box;
      font-size: 20px;
      font-family: PingFangSC-Medium, "PingFang SC";
      color: rgb(27, 34, 87);
      border-radius: 10px 10px 0px 0px;
    }
    .el-dialog__headerbtn{
      top:14px;
    }
    .el-dialog__body{
      padding:20px;
    }
    .tip-text{
      line-height:24px;
      &:nth-child(1){
        margin-bottom:8px;
      }
    }
    .el-dialog__footer{
      padding:12px 20px 24px;
    }
  }
  .breadName
    cursor pointer
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666
    &.active-color
      color #333
</style>
