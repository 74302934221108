<template>
	<div class="courseDetail">
		<el-collapse :class="['courseCollaps', className]" v-model="courseActiveName" accordion>
			<el-collapse-item :name="courseDetail.id">
				<!-- 课程名字 -->
				<template #title>
					<div class="courseAbstract">
						<div class="courseAbstracte_left">
							<span class="tag">
								课程
							</span>
							<span class="name">{{ courseDetail.contentName }}</span>
						</div>
						<div class="courseAbstract_right"></div>
					</div>
				</template>

                <div class="list">
                    <CourseItem :catalogList="courseDetail.childItemList" :courseDetail="courseDetail" :currentCourse="currentCourse" :defaultUnfoldCourse='defaultUnfoldCourse' :detailObj="detailObj" :isOverType="isOverType"></CourseItem>
				</div>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
// import CourseContentItem from './courseContentItem'
import CourseItem from './courseItem'
export default {
	name: 'courseDetail',
	data() {
		return {
			courseActiveName: '',
			defaultUnfold: {
				courseActiveNamea: '',
				courseActiveNameaa: '',
				courseActiveNameaaa: '',
			},
			currentCourse: {},
			catalogList: [], //课程章节
			lastStudyId: '',
		}
	},
	props: {
		courseDetail: Object,
		className: String,
		// 商品详情
		detailObj: {
			type: Object,
			default: () => {},
        },
        //是否过期
		isOverType: {
			type: String,
			default: 'no',
        },
        //默认展开课程id
        defaultUnfoldCourse:[Number,String]
	},
	components: {
        // CourseContentItem,
        CourseItem
    },
    watch:{
        defaultUnfoldCourse(val){
            this.courseActiveName = val
        }
    },
	mounted() {
        console.log(this.courseDetail, 'courseDetail================')
        // // 课程章节添加试看标识
		// this.findCourseItemByCourseId()
	},
	methods: {
		// 课程章节
		findCourseItemByCourseId() {
			this.$api.courseGoodsDetail
				.findResourceListById({
					id: this.courseDetail.contentId,
				})
				.then((res) => {
					if (res.data) {
                        this.catalogList = res.data
						//试看列表
						var newArray = this.detailObj.tryWatchList.map((item) => {
							return item.itemId
                        })
						//匹配试看信息
						this.catalogList.forEach((el) => {
							if (newArray.indexOf(el.id) > -1) {
								el.watchLength = this.detailObj.tryWatchList.find((v) => {
									return v.itemId == el.id
								}).value
								el.tryWatch = 1
							} else {
								el.tryWatch = 0
							}
						})
					}
				})
		},
	},
}
</script>

<style lang="stylus" scoped src="../css/courseDetail.styl"></style>
