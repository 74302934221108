<template>
  <div class="distributionHomePage">
    <div class="banner"></div>
    <div class="inform">
      <div class="con">
        <div class="label">
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/renshe/homePage/inform.png" alt="">
          <span class="title">通知公告</span>
          <span class="line"></span>
        </div>
        <div class="news">
          <ul>
            <li class="col-banner">
              <el-carousel height="24px" direction="vertical" :autoplay="true" :interval="3000">
                <el-carousel-item v-for="(item, index) in messageListLeft" :key="index" class="college-slide">
                  <div class="messageItem">
                    <span class="name">{{item.userName}}</span>
                    刚刚报名了《{{item.goodsName}}》
                  </div>
                  <div class="messageItem" v-if="messageListRight[index]">
                    <span class="name">{{messageListRight[index].userName}}</span>
                    刚刚报名了《{{messageListRight[index].goodsName}}》
                  </div>
                </el-carousel-item>
              </el-carousel>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="projectIntro-goodsList" style="margin-top:-60px':'margin-top:-20px;">
      <div class="classification">
          <div class="workType">
            <p class="label">培训项目：</p>
            <div class="itemList">
              <div class="item" v-for="(item,index) in trainingItemList" @click="trainingItemClick(item,index)" :class="{active:index===trainingItemIndex}" :key="index+'training'">{{item.vocationName}}</div>
            </div>
          </div>
          <div class="workType">
            <p class="label">培训工种：</p>
            <div class="itemList">
              <div class="item" v-for="(item,index) in workTypeList" @click="workTypeClick(item,index)" :class="{active:index===workTypeIndex}" :key="index+'workType'">{{item.vocationName}}</div>
            </div>
          </div>
          <div class="skillLevel">
            <p class="label">技能等级：</p>
            <div class="itemList">
              <div class="item" v-for="(item,index) in skillLevelList" @click="skillLevelClick(item,index)" :class="{active:index===skillLevelIndex}" :key="index+'skillLevel'">{{item.vocationName}}</div>
            </div>
          </div>
          <div class="trainMethod">
            <p class="label">培训方式：</p>
            <div class="itemList">
              <div class="item" v-for="(item,index) in trainMethodList" @click="trainMethodClick(item,index)" :class="{active:index===trainMethodIndex}" :key="index+'trainMethod'">{{item.name}}</div>
            </div>
          </div>
      </div>
      <div v-if="goodsList.length">
        <div class="list">
          <div class="item" v-for="item in goodsList" :key="item.id" @click="commodityDetails(item)">
            <div class="commonTag">
              <p class="trainMethod" v-if="item.trainingMethodName">{{item.trainingMethodName}}</p>
              <template v-for="(el,ind) in item.labelNameList">
                <span class="teacherTag" :key="ind">{{el}}</span>
              </template>
            </div>
            <img :src="item.pic" class="pic" alt="">
            <div class="middle">
              <h2 class="textCut">{{item.name}}</h2>
              <p class="time">
                培训周期：
                <span v-if="item.cycleType===1">{{item.trainingStartTime && item.trainingStartTime.split(' ')[0].replace(/-/g, '.')}} - {{item.trainingEndTime && item.trainingEndTime.split(' ')[0].replace(/-/g, '.')}}</span>
                <span v-if="item.cycleType===2">随到随学</span>
                <span v-if="item.cycleType===3">长期有效</span>
              </p>
              <p class="time">报名周期：{{item.signUpStartTime && item.signUpStartTime.split(' ')[0].replace(/-/g, '.')}} - {{item.signUpEndTime && item.signUpEndTime.split(' ')[0].replace(/-/g, '.')}}</p>
            </div>
            <p class="line"></p>
            <div class="bottom">
              <div class="login">
                <div class="left">
                  <div v-if="$store.state.app.isWdBranch===true">
                    <!-- <p class="" v-if="item.isFree==1">免费</p>
                    <p class="" v-else>¥{{item.price}}</p> -->
                  </div>
                </div>
                <div class="right">
                  <p :class="['btn', item.signUpExpired ? 'overdue' : '']" @click.stop="commodityDetails(item)">立即报名</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <span class="courseNum">共{{paging.total}}个课程</span>
          <el-pagination
            @size-change="handleSizeChange"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="paging.params.pageNum"
            :page-size="paging.params.pageSize"
            layout="prev, pager, next, jumper"
            :total="paging.total">
          </el-pagination>
        </div>
      </div>
      <div v-else class="nodata">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/renshe/homePage/zwss.png" alt="">
        <span>暂无内容</span>
      </div>
    </div>
    <div class="footer">
      <div class="con">
        <span>主办单位：人力资源和社会保障部社会保障能力建设中心</span>
        <span>京ICP备 13009107号-3</span>
        <span>京公网安备 11010502033661</span>
        <span>技术支持：伟东云教育集团</span>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import URL from '../../../config/url.js'
export default {
  name: "distributionHomePage",
  data() {
    return {
      token:'',
       transLevel:{
                1:'01',
                2:'02',
                3:'03',
                4:'04',
                5:'05'
            },
      messageList: [],
      messageListLeft: [],
      messageListRight: [],
      skillLevelIndex:0, // 技能等级
      workTypeIndex:0, // 工种下标
      trainingItemIndex:0, // 培训项目
      trainMethodIndex:0, // 培训方式
      skillLevelList: [
        {
          vocationCode: 0,
          vocationName: '全部',
        }
      ],
      workTypeList:[
        {
          vocationCode: 0,
          vocationName: '全部',
        }
      ],// 工种列表
      trainingItemList: [
        {
          vocationCode: 0,
          vocationName: '全部',
        }
      ],
      trainMethodList: [
        {
          id: 0,
          name: '全部',
        }
      ],
      sortIndex:1,
      paging: {
        params: {
          pageNum: 1,
          pageSize: 8
        },
        total: 0,
        totalPages: 0,
        currentSize: 0,
      },
      goodsList:[],// 商品列表
      goodsListParams:{
        branchId: '',// 机构id
        centerType:2,//1 服务商 2 渠道方
        fangshi:'',// 培训方式code
        gongzhong:'',// 培训工种
        jineng:'',// 技能等级
        sort:1,//排序规则 1 智能排序；2 最热排序；3 佣金
        zhiye: '',
        teacherFlag:2,//1:师资，2:师资+非师资 空：非师资
      },
      branchId: '',
      screenList: {}
    };
  },
  async created(){
      if(!localStorage.getItem('branchId')){
          await this.$api.configure.configurationNew(URL.confUrl).then((res) => {
            if (res.data) {
              localStorage.setItem('branchId', res.data.branchId || '')
            }
          })
      }
      this.goodsListParams.branchId = localStorage.getItem('branchId');
      this.branchId = localStorage.getItem('branchId');
      this.token = localStorage.getItem('token');
      await this.filterInfo();
      await this.findOrderUser();
      await this.getData();
  },
  async mounted(){
    
   
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.getData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getData();
    },
    /** 技能等级 */
    skillLevelClick(item,index){
      this.skillLevelIndex = index;
      this.goodsListParams.jineng = index === 0 ? '' : item.vocationCode;
      this.paging.params.pageNum = 1;
      this.getData();
    },
    /** 培训方式 */
    trainMethodClick(item,index){
      this.trainMethodIndex = index;
      this.goodsListParams.fangshi = index === 0 ? '' : item.id;
      this.paging.params.pageNum = 1;
      this.getData();
    },
    /** 培训工种点击 */
    workTypeClick(item,index){
      this.workTypeIndex = index;
      this.skillLevelList = [
        {
          vocationCode: 0,
          vocationName: '全部',
        }
      ]
      if (index===0) {
        this.goodsListParams.gongzhong = '';
        this.skillLevelList = this.skillLevelList.concat(this.screenList.level);
      } else {
        this.goodsListParams.gongzhong = item.vocationCode;
        this.skillLevelList = this.skillLevelList.concat(item.subVocations);
      }
      this.skillLevelIndex = 0;
      this.goodsListParams.jineng = '';
      this.paging.params.pageNum = 1;
      this.getData();
    },
    /** 培训项目点击 */
    trainingItemClick(item,index){
      this.trainingItemIndex = index;
      this.workTypeList = [
        {
          vocationCode: 0,
          vocationName: '全部',
        }
      ]
      this.skillLevelList = [
        {
          vocationCode: 0,
          vocationName: '全部',
        }
      ]
      if (index===0) {
        this.goodsListParams.zhiye = '';
        this.workTypeList = this.workTypeList.concat(this.screenList.workType);
      } else {
        this.goodsListParams.zhiye = item.vocationCode;
        this.workTypeList = this.workTypeList.concat(item.subVocations);
      }
      this.skillLevelList = this.skillLevelList.concat(this.screenList.level);
      this.workTypeIndex = 0;
      this.skillLevelIndex = 0;
      this.goodsListParams.gongzhong = '';
      this.goodsListParams.jineng = '';
      this.paging.params.pageNum = 1;
      this.getData();
    },
    /** 商品列表 */
    async getData(){
      const data = {
        ...this.goodsListParams
      }
      const params = {
        ...this.paging.params
      }
      if(data.gongzhong && [1,2,3,4,5].includes(data.jineng)){
        data.jineng =data.gongzhong+'-'+this.transLevel[data.jineng]
      }
     await this.$api.mall.distributionGoodsList(data,params).then(res => {
        if(res.data){
          this.goodsList = res.data.list || [];
          this.paging.total = res.data.total;
          this.paging.params.pageNum = res.data.pageNum;
        }
      })
    },
    async filterInfo() {
      const params = {
        branchId: this.branchId,
        centerType: 1,
        tagFlag:2
      }
      await this.$api.mall.filterInfo(params).then(res => {
        if(res.data){
          this.screenList = res.data;
          // 培训项目
          this.trainingItemList = this.trainingItemList.concat(res.data.category);
          // 培训工种
          this.workTypeList = this.workTypeList.concat(res.data.workType);
          // 技能等级
          this.skillLevelList = this.skillLevelList.concat(res.data.level);
          // 培训方式
          this.trainMethodList = this.trainMethodList.concat(res.data.label);
        }
      })
    },
    async findOrderUser() {
      await this.$api.mall.findOrderUser(this.branchId).then(res => {
        if(res.success){
          this.messageList = res.data;
          this.messageList.forEach((ele, index)=>{
            if (index%2===0) {
              this.messageListLeft.push(ele)
            } else {
              this.messageListRight.push(ele)
            }
          })
        }
      })
    },
    commodityDetails(item) {
      if (item.signUpExpired) {
        this.$message.warning('报名已过期')
      }
      this.$router.push({
        path:'/mall/goodsDetail',
        query:{
          goodsKind:item.goodsKind,
          goodsId:item.id,
          fromType:1
        }
      })
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '../asset/css/distributionHomePage.styl';
</style>
