<template>
  <div id="PdfPReview">
  </div>
</template>

<script type="text/ecmascript-6">

  export default {
    data() {
        return {
            pdfUrl: ''
        }
    },
    created() {
        
    },
    mounted() {
        
        this.getImmPreview()
    },
    methods: {
        async getImmPreview() {
            let res = await this.$api.mall.getImageOrDocUrls(this.$route.query.id)
            console.log(res)
            if(res.success && res.data) {
                // eslint-disable-next-line no-undef
                let demo = aliyun.config({
                    mount: document.querySelector('#PdfPReview'),
                    url: res.data.previewURL
                })
                demo.setToken({
                    token: res.data.accessToken
                })
            }
        }
    }
    
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

 #PdfPReview
     width 100%
     height: 100vh
</style>
