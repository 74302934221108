<template>
  <div class="standard-list-wraper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item ><span class="breadName" @click="$router.go(-1)">首页</span></el-breadcrumb-item>
        <el-breadcrumb-item><span class="breadName active-color">全部标准</span></el-breadcrumb-item>
      </el-breadcrumb>
      <ul class="list">
        <li class="item"
          v-for="(item,index) in standardList.slice(0,10)"
          :key="index"
        >
          <div class="index-name">
            <span class="index">{{(index+1)>9?index+1:`0${index+1}`}}</span>
            <span class="text">{{item.work_url_name}}</span>
          </div>
          <span class="see-detail" @click="seeDetail(item.work_url)">查看详情</span>
        </li>
      </ul>
      <!-- <el-dialog
        title="查看附件"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose">
        <span>这是一段信息</span>

      </el-dialog> -->
      <div class="blank">a</div>
  </div>
</template>

<script type="text/ecmascript-6">
// import SDK from '@wd/lowcode-pc'

  export default {
    name: 'StandardList',
    data() {
      return {
        dialogVisible: false,
        standardList: []
      }
    },
    async created(){
      // this.saasid = await SDK.getConfigruation(this.$store).saasid;
      // this.branchId = await SDK.getConfigruation(this.$store).branchid;
    },
    mounted() {
      this.getStandardList()
    },
    methods: {
      async getStandardList() {
        // let dataObj = {
        //   "className":"培训标准",
        //   "saasId": this.saasid,
        //   "branchId": this.branchId
        // }
        // let res = await this.$api.mall.getStandardList(dataObj)
        // if(res.success && res.data.list.length) {
        //   this.standardList = res.data.list
        // }
        let res = await this.$api.mall.getWorkUrl()
        console.log(res)
        if(res.success && res.data &&res.data.length) {
          this.standardList = res.data.filter(item => item.id !== null)
        }
      },
      seeDetail(id) {
        if(id === null) return
        // this.$router.push({
        //   path: '/mall/pdfDetail',
        //   query: {
        //     id
        //   }
        // })
        const url = window.location.protocol+'//'+window.location.hostname
        window.open(`${url}/mall/pdfDetail?id=${id}`, '_blank')


      },
      handleClose() {
        this.dialogVisible = false

      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.standard-list-wraper
  width: 1200px
  height calc(100vh - 72px - 24px)
  margin: 24px auto 0
  display: flex 
  flex-direction: column 
  box-sizing: border-box
  .breadName
    cursor pointer
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666
    &.active-color
      color #333
  .blank
    height: 24px
    color rgba(0,0,0,0)
  .list
    background-color: #fff
    padding: 24px
    margin-top 24px
    flex 1
    .item
      height: 80px
      display: flex
      align-items: center
      justify-content: space-between
      box-sizing: border-box
      border-bottom: 1px solid #e6e6e6 
      &:hover 
        background-color: #F8F9FA
        .index-name
          .index 
            color: #316FFF;
          .text 
            color: #316FFF;
      .index-name
        display: flex 
        align-items: center
        .index 
          height: 42px;
          font-size: 32px;
          font-family: Impact;
          color: #EEEEEE;
          line-height: 42px;
          margin-right 16px
          margin-left 16px
        .text 
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 22px
      .see-detail 
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #316FFF;
        line-height: 20px
        margin-right 16px
        cursor: pointer


  /deep/.el-dialog{
    border-radius: 16px
    height 634px
  }
  /deep/.el-dialog__header{
    height: 40px;
    box-sizing: border-box;
    padding: 0px 16px;
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid #E8F1FF

  }
  /deep/.el-dialog__title{
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333
    flex 1
    text-align: center
  }
  /deep/.el-dialog__headerbtn{
    position:static
  }
  /deep/.el-dialog__close{
    position: static
    font-size: 18px;
    font-weight: bold
  }
  /deep/.el-dialog__body{
    padding: 24px 24px 42px 24px
    height: 560px
    box-sizing: border-box
  } 
</style>
