import { render, staticRenderFns } from "./standard-list.vue?vue&type=template&id=b0030bc2&scoped=true&"
import script from "./standard-list.vue?vue&type=script&lang=js&"
export * from "./standard-list.vue?vue&type=script&lang=js&"
import style0 from "./standard-list.vue?vue&type=style&index=0&id=b0030bc2&prod&lang=stylus&rel=stylesheet%2Fstylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0030bc2",
  null
  
)

export default component.exports