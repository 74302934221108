<template>
	<div>
		<div class="taskItem taskItem0" v-for="taskItem in taskCatalogList" :key="taskItem.id">
			<!-- 培训任务没有目录 -->
			<template v-if="!hasTaskCatalog(taskItem)">
				<TaskContentItem
					:taskItem="taskItem"
					:detailObj="detailObj"
					:isOverType="isOverType"
					:defaultUnfoldCourse="defaultUnfoldCourse"
				></TaskContentItem>
			</template>

			<!-- 一级目录 -->
			<template v-else>
				<el-collapse class="taskCollapse" :class="{ locked: taskItem.isLocked }" v-model="taskActiveNamea" accordion>
					<el-collapse-item :name="taskItem.id" :disabled="taskItem.isLocked">
						<template #title>
							<div class="taskTitle" @click="handleClickCatalog(taskItem)">
								<div class="offTrain_top" v-if="taskItem.type==8">
									<span class="flagTag">
										{{ tagList[taskItem.type] }}
									</span>
									<span class="name">{{ taskItem.contentName }}</span>
								</div>
								<span v-else>{{ taskItem.stageName }}</span>
								<svg-icon v-if="taskItem.isLocked" icon-class="lock"></svg-icon>
							</div>
						</template>
						<!-- 线下实训 推广+有实训地信息时候，展示实训基地信息-->
						<template v-if="taskItem.type==8 && taskItem.childItemList && taskItem.childItemList.length>0">
							<div class="trainAddItem" v-for="(item2,index) in taskItem.childItemList" :key="index" @click="toStudy">
								<span class="name"> <svg-icon icon-class="address" style="flex-shrink:0;"></svg-icon> <span style="margin-left:16px;">{{item2.trainingBaseName}}</span></span>
								<span class="address">地址：{{item2.addressDetail}}</span>
								<span class="user">联系人：{{item2.userName}}</span>
								<span class="phone">电话：{{item2.phone}}</span>
							</div>
						</template>
						<div class="taskItem taskItem1" v-else v-for="item1 in taskItem.childItemList" :key="item1.id">
							<template v-if="!hasTaskCatalog(item1)">
								<TaskContentItem
									:taskItem="item1"
									:detailObj="detailObj"
									:isOverType="isOverType"
									:defaultUnfoldCourse="defaultUnfoldCourse"
									:className="'innerTaskCatalog'"
								></TaskContentItem>
							</template>

							<!-- 二级目录 -->
							<template v-else>
								<el-collapse class="taskCollapse taskCollapse2" v-model="taskActiveNameaa" accordion>
									<el-collapse-item :name="item1.id" :disabled="item1.isLocked">
										<template #title>
											<div class="taskTitle taskTitle2" @click="handleClickCatalog(item1)">
												<span>{{ item1.stageName }}</span>
												<svg-icon v-if="item1.isLocked" icon-class="lock"></svg-icon>
											</div>
										</template>

										<div class="taskItem taskItem2" v-for="item2 in item1.childItemList" :key="item2.id">
											<TaskContentItem
												:taskItem="item2"
												:detailObj="detailObj"
												:isOverType="isOverType"
												:defaultUnfoldCourse="defaultUnfoldCourse"
												:className="'innerTaskCatalog'"
											></TaskContentItem>
										</div>
									</el-collapse-item>
								</el-collapse>
							</template>
						</div>
					</el-collapse-item>
				</el-collapse>
			</template>
		</div>
	</div>
</template>

<script>
import TaskContentItem from './taskContentItem'
export default {
	name: 'taskItem',
	data() {
		return {
			taskActiveNamea: '',
			taskActiveNameaa: '',
			defaultUnfoldCourse: '', //默认展开课程id
			tagList: { 3: '考试', 4: '直播', 5: '线上实训', 6: '表单', 7: '练习', 8: '线下实训' },
			validity: '', // 是否显示有效期
		}
	},
	props: {
		taskCatalogList: Array,
		//当前层级对象
		itemObj: {
			type: Object,
			default: () => {},
		},
		//商品ID
		goodsId: {
			type: Number,
			default: 1,
		},
		//是否过期
		isOverType: {
			type: String,
			default: 'no',
		},
		// 商品详情
		detailObj: {
			type: Object,
			default: () => {},
		},
		//是否是分销商登录
		fromType:{
			type: Number,
			default: 1,
		}
	},
	components: {
		TaskContentItem,
	},
	mounted() {
		const configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
		// 是否显示训练营，任务，测评 有效期
		this.validity =
			configurationArr &&
			configurationArr.filter((item) => {
				if (item.key === 'is_show_task_term_validity') {
					return true
				}
			})[0].value;
		//
		let catalog1 = this.taskCatalogList[0]
		if (catalog1.type == 1) {//培训任务目录
			let catalog2 = catalog1.childItemList[0]
			if (catalog2.type == 1) {
                this.taskActiveNamea = catalog1.id
				//二级目录
				this.taskActiveNameaa = catalog2.id
				this.defaultUnfoldCourse = catalog2.childItemList[0].id
			} else {
				//一级目录
				this.taskActiveNamea = catalog1.id
				this.defaultUnfoldCourse = catalog1.childItemList[0].id
			}
		} else if(catalog1.type == 2){//课程
            this.defaultUnfoldCourse = catalog1.id
        }
	},
	methods: {
		// 有无培训目录
		hasTaskCatalog(taskItem) { //目录type为1，线下实训type=8 分销商登录并且下面有实训基地的时候也可展开
			if (taskItem.type === 1 || this.fromType==3 && taskItem.type==8 && taskItem.childItemList && taskItem.childItemList.length>0) {
				return true
			} else {
				return false
			}
        },
        handleClickCatalog(){
            
        },
		toStudy() {
			if (!this.detailObj.isOrder) {
				this.$toast('报名后才可以查看')
				return
			}

			if (this.detailObj.goodsType == 1) {
				this.$router.push({
					path: '/course/catalog',
					query: {
						taskId: this.detailObj.referId, //训练营id
						taskType: this.detailObj.goodsType,
						goodsId: this.$route.query.goodsId,
						backPath: this.$route.path,
						goodsKind: this.$route.query.goodsKind,
						validity: this.validity || '',
					},
				})
			} else if (this.detailObj.goodsType == 2) {
				this.$router.push({
					path: '/learning/taskDetail',
					query: {
						id: this.detailObj.referId, //培训任务id
						goodsId: this.$route.query.goodsId,
						backPath: this.$route.path,
						type: this.detailObj.goodsType,
						goodsKind: this.$route.query.goodsKind,
						validity: this.validity || '',
					},
				})
			} else if (this.detailObj.goodsType == 3) {
				this.$router.push({
					path: '/assess',
					query: {
						assessId: this.detailObj.referId, //测评id
						refId: this.$route.query.goodsId,
						refType: 2,
						goodsId: this.$route.query.goodsId,
						pathName: this.$route.path,
						goodsKind: this.$route.query.goodsKind,
					},
				})
			}
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../css/taskItem.styl'
</style>
