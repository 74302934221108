<template>
	<div>
		<div class="courseItem courseItem0" v-for="courseItem in catalogList" :key="courseItem.id">
			<!-- 作业 -->
			<template v-if="courseItem.homeworkId">
				<div class="work" @click="toWork(courseItem)">
					<div class="courseContentItem_left">
						<svg-icon icon-class="study_zuoye"></svg-icon>

						<span>{{ courseItem.homeworkName }}</span>
					</div>
					<div class="courseContentItem_right">
						<span v-if="courseItem.status == 0">未提交</span>
						<span class="highLight" v-else-if="courseItem.status == 1">已提交</span>
						<span class="remarked" v-else>已点评</span>
					</div>
				</div>
			</template>
			<!-- 没有目录 -->
			<template v-else-if="!hasCourseCatalog(courseItem)">
				<CourseContentItem
					:taskId="courseDetail.taskId"
					:courseItem="courseItem"
					:currentCourse="currentCourse"
					:detailObj="detailObj"
					:isOverType="isOverType"
				></CourseContentItem>
			</template>

			<template v-else>
				<!-- 第一个目录 -->
				<el-collapse class="course-1" v-model="courseActive1" accordion>
					<el-collapse-item :name="courseItem.id">
						<template #title>
							<div class="courseTitle course-1Title">
								<span class="circle"></span>
								<span class="name">{{ courseItem.contentName }}</span>
							</div>
						</template>

						<div class="courseItem courseItem1" v-for="(item1,index1) in courseItem.childItemList" :key="item1.id">
							<template v-if="!hasCourseCatalog(item1)">
								<CourseContentItem
									:taskId="courseDetail.taskId"
									:courseItem="item1"
									:currentCourse="currentCourse"
									:detailObj="detailObj"
									:isOverType="isOverType"
									:lastItem="index1==courseItem.childItemList.length-1"
								></CourseContentItem>
							</template>

							<!-- 第二个目录 -->
							<template v-else>
								<el-collapse class="course-1-1" v-model="courseActive2" accordion>
									<el-collapse-item :name="item1.id">
										<template #title>
											<div class="courseTitle course-1-1Title">
												<span class="circle"></span>
												<span class="name">{{ item1.contentName }}</span>
											</div>
										</template>

										<div class="courseItem courseItem2" v-for="(item2,index2) in item1.childItemList" :key="item2.id">
											<template v-if="!hasCourseCatalog(item2)">
												<CourseContentItem
													:taskId="courseDetail.taskId"
													:courseItem="item2"
													:currentCourse="currentCourse"
													:detailObj="detailObj"
													:isOverType="isOverType"
													:lastItem="index2==item1.childItemList.length-1"
												></CourseContentItem>
											</template>

											<!-- 第三个目录 -->
											<template v-else>
												<el-collapse class="course-1-1-1" v-model="courseActive3" accordion>
													<el-collapse-item :name="item2.id">
														<template #title>
															<div class="courseTitle course-1-1-1Title">
																<span class="circle"></span>
																<span class="name">{{ item2.contentName }}</span>
															</div>
														</template>

														<div class="courseItem courseItem3" v-for="(item3,index3) in item2.childItemList" :key="item3.id">
															<template v-if="!hasCourseCatalog(item3)">
																<CourseContentItem
																	:taskId="courseDetail.taskId"
																	:courseItem="item3"
																	:currentCourse="currentCourse"
																	:detailObj="detailObj"
																	:isOverType="isOverType"
																	:lastItem="index3==item2.childItemList.length-1"
																></CourseContentItem>
															</template>
														</div>
													</el-collapse-item>
												</el-collapse>
											</template>
										</div>
									</el-collapse-item>
								</el-collapse>
							</template>
						</div>
					</el-collapse-item>
				</el-collapse>
			</template>
		</div>
	</div>
</template>

<script>
import CourseContentItem from './courseContentItem'
export default {
	name: 'CourseItem',
	data() {
		return {
			courseActive1: '',
			courseActive2: '',
			courseActive3: '',
		}
	},
	props: {
		catalogList: Array,
		courseDetail: Object,
		currentCourse: Object,
		defaultUnfold: {
			//默认展开
			type: Object,
			default: () => ({
				courseActiveNamea: '',
				courseActiveNameaa: '',
				courseActiveNameaaa: '',
			}),
		},
		// 商品详情
		detailObj: {
			type: Object,
			default: () => {},
		},
		//是否过期
		isOverType: {
			type: String,
			default: 'no',
		},
		//默认展开课程id
		defaultUnfoldCourse: [Number, String],
	},
	components: {
		CourseContentItem,
	},
	watch: {
		defaultUnfoldCourse() {
			let catalog1 = this.catalogList && this.catalogList[0]
			let catalog2 = catalog1 && catalog1.childItemList && catalog1.childItemList[0]
			let catalog3 = catalog2 && catalog2.childItemList && catalog2.childItemList[0]

			// console.log(this.defaultUnfoldCourse, catalog1.parentId, '------------------')
			if (catalog1 && this.defaultUnfoldCourse === catalog1.parentId) {
				this.courseActive1 = catalog1 && catalog1.id
				this.courseActive2 = catalog2 && catalog2.id
				this.courseActive3 = catalog3 && catalog3.id
			}
        },
        
	},
	mounted() {},
	methods: {
		// 作业
		toWork(courseItem) {
			this.$router.push({
				path: '/learning/assignment',
				query: {
					courseId: this.courseDetail.contentId,
					homeworkId: courseItem.homeworkId,
					taskId: this.courseDetail.taskId,
				},
			})
		},
		hasCourseCatalog(courseItem) {
			if (courseItem.childItemList && courseItem.childItemList.length) {
				return true
			} else {
				return false
			}
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../css/courseItem.styl'
</style>
